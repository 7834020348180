<template>
  <div>
    <v-layout row wrap class="ma-0 pa-1">
      <v-flex xl6 lg8 md6 class="pa-1">
        <v-card class="d-inline-flex align-center" width="100%" height="100%">
          <v-card-text>
            <v-layout row wrap align-center class="ma-0">
              <v-flex lg8 md7 class="pl-12 pl-xl-16">
                <p class="mb-0 d-flex title black--text font-weight-bold"> {{ $moment().format('dddd, MMMM Do') }} </p>
                <p class="mb-0 text-h4 font-weight-bold"> {{ greetingsBasedOnTime }}, </p>
                <p class="mb-0 text-h5 font-weight-black text-capitalize"> {{ userDetails?.formdata?.name }} </p>
              </v-flex>
              <v-flex lg4 md5 class="px-4 px-xl-12">
                <v-img
                  lazy-src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdni.iconscout.com%2Fillustration%2Fpremium%2Fthumb%2Fstudents-studying-online-2974943-2477369.png&f=1&nofb=1"
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdni.iconscout.com%2Fillustration%2Fpremium%2Fthumb%2Fstudents-studying-online-2974943-2477369.png&f=1&nofb=1"
                  height="100%"
                  width="100%"
                  contain
                ></v-img>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xl3 lg4 md3 class="pa-1">
        <v-card height="330" style="overflow-y: auto; overflow-x: hidden">
          <v-card-title> Recent Events <v-chip x-small color="primary" class="mt-1 mx-2">Month</v-chip></v-card-title>
          <v-divider></v-divider>
          <v-timeline align-top dense v-if="listOfEvents.length">
            <v-timeline-item color="pink" small v-for="event in listOfEvents" :key="event._id">
              <v-row class="px-0 ml-n7 mt-n4">
                <v-col>
                  <strong> {{ event.title }} </strong>
                  <div class="caption"> {{ $_formatDate(event.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY - HH:mm') }} </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <v-card-text v-else>
            There Are No Events
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xl3 lg3 md3 class="pa-1">
        <v-card height="330" max-height="330">
          <v-card-title>Active Users</v-card-title>
          <v-divider></v-divider>
          <v-list-item two-line v-for="(user, index) in activeUsers" :key="index" class="mt-3">
            <v-list-item-avatar>
              <v-img :src="`${imageRootUrl}profile/${user.profile}` || 'https://randomuser.me/api/portraits/women/81.jpg'"
              :alt="user?.formdata?.name || 'user'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="font-weight-black">
                  {{ user?.formdata?.name || 'Unknown User' }}
                </span>
                <span>
                  <v-chip x-small> {{ (user.role && user.role.name) ? user.role.name : 'No Role' }} </v-chip>
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-flex>
      <v-flex xl4 lg3 md3 class="pa-1">
        <v-card height="330" max-height="330" style="overflow-y: auto; overflow-x: hidden">
          <v-card-title> Recent Events <v-chip x-small color="primary" class="mt-1 mx-2">Month</v-chip></v-card-title>
          <v-divider></v-divider>
          <v-timeline align-top dense v-if="listOfEvents.length">
            <v-timeline-item color="pink" small v-for="event in listOfEvents" :key="event._id">
              <v-row class="px-0 ml-n7 mt-n4">
                <v-col>
                  <strong> {{ event.title }} </strong>
                  <div class="caption"> {{ $_formatDate(event.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY - HH:mm') }} </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <v-card-text v-else>
            There Are No Events
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xl4 lg6 md6 class="pa-1">
        <v-card height="330" max-height="330" style="overflow-y: auto; overflow-x: hidden">
          <v-card-title> Recent Events <v-chip x-small color="primary" class="mt-1 mx-2">Month</v-chip></v-card-title>
          <v-divider></v-divider>
          <v-timeline align-top dense v-if="listOfEvents.length">
            <v-timeline-item color="pink" small v-for="event in listOfEvents" :key="event._id">
              <v-row class="px-0 ml-n7 mt-n4">
                <v-col>
                  <strong> {{ event.title }} </strong>
                  <div class="caption"> {{ $_formatDate(event.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY - HH:mm') }} </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <v-card-text v-else>
            There Are No Events
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xl4 lg6 md6 class="pa-1" v-if="$vuetify.breakpoint.xlOnly">
        <v-card height="330" max-height="330" style="overflow-y: auto; overflow-x: hidden">
          <v-card-title> Recent Events <v-chip x-small color="primary" class="mt-1 mx-2">Month</v-chip></v-card-title>
          <v-divider></v-divider>
          <v-timeline align-top dense v-if="listOfEvents.length">
            <v-timeline-item color="pink" small v-for="event in listOfEvents" :key="event._id">
              <v-row class="px-0 ml-n7 mt-n4">
                <v-col>
                  <strong> {{ event.title }} </strong>
                  <div class="caption"> {{ $_formatDate(event.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY - HH:mm') }} </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <v-card-text v-else>
            There Are No Events
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      post: {},
      listOfEvents: {},
    }
  },
  computed: {
    ...mapGetters(['activeUsers']),
    greetingsBasedOnTime () {
      let currentHour = this.$moment().format('HH')
      if (currentHour >= 3 && currentHour < 12) return 'Good Morning'
      if (currentHour >= 12 && currentHour < 15) return 'Good Afternoon'
      if (currentHour >= 15 && currentHour < 20) return 'Good Evening'
      if (currentHour >= 20 && currentHour < 3) return 'Good Night'
      return 'Hello'
    }
  },
  mounted () {
    this.getListOfEvents()
    // this.$eventBus.$on('MessageSentOrReceived', (msg) => {
    //   let text = msg.sender === this.activeUserId ? 'Sent' : 'Received'
    //   console.log(msg, text)
    //   this.activeUsers = msg[1]
    // })
    // let payload = ['60c1ba20efaf6b52389d9f31']
    // this.$_execute('post', '/users/contacts/add', payload)
    // let payload = { skip: 0,  }
    // this.$_execute('post', 'users/contacts/getContacts', {})
    // let payload = false
    // this.$_execute('post', `users/contacts/${payload ? 'add' : 'remove'}`, payload ? ['60ab3126173fa1146c6ae5ed', '60ab3126173fa1146c6ae5ee'] : ['60ab3126173fa1146c6ae5ee'])
  },
  methods: {
    createPost () {
      this.$store.dispatch('createPost', { content: this.post.content })
      this.post.content = ''
    },
    getListOfEvents() {
      let payload = {
        start: this.$_localToUtcUnix(this.$moment().startOf('month').format('YYYY-MM-DDT00:00')),
        end: this.$_localToUtcUnix(this.$moment().endOf('month').format('YYYY-MM-DDT23:59'))
      }
      this.$_execute('post', 'events/filter', payload).then(({ data }) => {
        data.forEach(x => {
          x.start = this.$_utcUnixToLocalDateTime(x.start)
          x.end = this.$_utcUnixToLocalDateTime(x.end)
        })
        this.listOfEvents = data
      })
    }
  }
}
</script>
